@use 'variables';

@layer elements {
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  button {
    background: none;
    border: 0;
  }

  .text-box {
    box-sizing: border-box;
    margin: auto;
    padding: clamp(24px, 4vw ,60px);
    // @include corneredElem(16px);
    @include variables.bgElem;
  }

  .item-caption {
    width: 85%;
    position: relative;
    //margin: -36px 7.5% 0;
    padding: 24px;
    background: #fff;

    @include variables.bgElem('white');
    // @include corneredElem(16px);
  }

  a.button {
    display: inline-flex !important;
    padding: 8px 24px 8px 30px;
    border: 2px solid variables.$colorBlack;
    border-radius: 50px;
    background: none;

    font-size: 1em;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    gap: 12px;
    align-items: center;

    &, *, &::after {
      transition: 0.3s variables.$dnldBezier;
    }

    &::after {
      content: '';
      @include variables.trianglePseudoElem(0.9ch, false);
    }

    &:hover {
      &::after {
        transform: scale(0.8);
        transform-origin: left center;
      }
    }

    &, &--inverted:hover {
      background: variables.$colorBlack !important;
      color: variables.$colorWhite !important;

      &::after {
        background-image: variables.imageFromAssetFolder('triangle-white.svg');
      }
    }

    &:hover, &--inverted {
      background: variables.$colorWhite !important;
      color: variables.$colorBlack !important;

      &::after {
        background-image: variables.imageFromAssetFolder('triangle.svg');
      }
    }
  }

  // Carousel
  .carousel-inner {
    overflow: hidden;
    position: relative;
    min-height: var(--carousel-height);
  }

  .slide {
    width: 100%;
    position: absolute;
    transition: transform 0.5s ease-in-out;
  }

  .slide-content {
    position: relative;
    z-index: 5000;
  }

  .slide img {
    width: 100%;
    object-fit: cover;
  }

  .slide-caption {
    width: 100%;
    position: absolute;
    padding: 2em;
    left: 0;
    bottom: 0;
  }

  .carousel-btn { }
  .carousel-btn--prev-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  .carousel-btn--prev { left: 2em; }
  .carousel-btn--next { right: 2em; }

  .carousel-pagination {
    display: flex;
    gap: .75em;
  }
}