@use "variables";

@layer header {
  .header {
    position: fixed;

    width: 100vw;
    z-index: 100;
    top: 0;
    background: variables.$colorBlack;

    // @media not #{$tablet-breakpoint} {
    //   background: $//linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.8) 30%, rgba(0, 0, 0, 0.6) 60%, rgba(0, 0, 0, 0) 100%);
    // }

    // @media #{$tablet-breakpoint} {
    //   background: black;
    // }

    $largeHeaderHeight: 110px;
    $largeMobileHeaderHeight: 100px;
    $smallHeaderHeight: 70px;

    @mixin applyHeaderAndLogoHeight($height) {
      height: $height;
      max-height: $height;

      a.logo {
        svg {
          height: calc($height - 16px);
          max-height: calc($height - 16px);
        }
      }
    }

    .wrap {
      display: flex;
      align-items: center;
      position: relative;
      padding: 16px 30px;
      box-sizing: border-box;

      @include applyHeaderAndLogoHeight($largeHeaderHeight);

      @media #{variables.$smartphone-breakpoint} {
        @include applyHeaderAndLogoHeight($largeMobileHeaderHeight);
        justify-content: flex-end;
        padding: 16px 24px;
      }

      svg, a {
        color: variables.$colorWhite;
        text-decoration: none;
      }

      a.logo {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        svg {
          width: auto;
        }
      }

      &, a.logo svg {
        transition: 0.4s ease-out;
      }
    }

    &__main {
      display: flex;
      flex: 1 1 auto;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      ul {
        display: flex;

        li {
          list-style: none;

          a {
            text-transform: uppercase;
            letter-spacing: 2px;

          }
        }
      }

      @media not #{variables.$tablet-breakpoint} {
        nav {
          align-self: center;
          text-align: center;

          ul {
            gap: 20px;
            li {
              list-style: none;
              a {
                font-size: 14px;
                position: relative;

                .decor {
                  pointer-events: none;
                  &::before, &::after {
                    position: absolute;
                    content: "";
                    transition: 0.5s ease-in-out;
                    opacity: 0;
                  }
                }

                &:hover {
                  .decor {
                    pointer-events: all;
                    &::before, &::after {
                      opacity: 1;
                    }
                  }
                }

                i {
                  &.fab, &.fas {
                    font-size: 30px;
                  }
                }
              }
            }
            &.menu {
              a {
                .decor {
                  &::before {
                    top: calc(100% + 4px);
                    left: 50%;
                    transform: translate(-50%);
                    width: calc(100% + 8px);
                    height: 1px;
                    background: variables.$colorWhite;
                    max-width: 0px;
                  }

                  &::after {
                    top: calc(100% + 4px);
                    left: 50%;
                    transform: translate(-50%, 20px) rotate(-90deg);
                    transition-duration: 0.3s;
                    @include variables.trianglePseudoElem(8px);
                  }

                }
                &:hover {
                  .decor {
                    pointer-events: all;
                    &::before {
                      max-width: 200px;
                    }

                    &::after {
                      transform: translate(-50%, 4px) rotate(-90deg);
                    }
                  }
                }
              }
            }

            &.socials {
              li {
                a {
                  i {
                    transition: 0.2s ease-out;
                  }
                  &:hover {
                    i {
                      transform: scale(1.2);
                    }
                  }
                  // .decor {
                  //   &::after {
                  //     bottom: calc(100% + 4px);
                  //     left: 50%;
                  //     transform: translate(-50%, -24px) rotate(90deg);
                  //     transition-duration: 0.3s;
                  //     @include trianglePseudoElem(12px);
                  //   }
                  // }
                  // &:hover {
                  //   .decor {
                  //     &::after {
                  //       transform: translate(-50%, -8px) rotate(90deg);
                  //     }
                  //   }
                  // }
                }
              }
            }
          }
        }
      }

      @media #{variables.$tablet-breakpoint} {
        background: variables.$colorBlack;
        padding: 16px 45px 45px;
        width: 100vw;

        position: absolute;
        top: 100%;
        height: calc(100dvh - 100%);
        right: 0;

        transform:translateX(120%);

        flex-direction: column;
        align-items: stretch;
        justify-content: flex-end;

        nav {
          &.nav {
            margin: auto 0;
          }
          ul {
            &.menu {
              display: flex;
              flex-direction: column;

              gap: clamp(24px, 8vw, 40px);
              li {
                display: flex;
                gap: 1ch;
                align-items: center;
                font-size: clamp(24px, 8vw, 40px);

                a {
                  font-size: inherit;
                }

                &::before {
                  content: "";
                  @include variables.trianglePseudoElem(0.5ch);
                }
              }
            }

            &.socials {
              justify-content: space-between;
              gap: 12px;

              i {
                &.fab, &.fas {
                  font-size: 30px;
                }
              }
            }
          }
        }
      }

    }

    @media #{variables.$tablet-breakpoint} {
      * {
        transition: 0.4s variables.$dnldBezier;
      }
      .menu-button {
        height: 24px;
        flex: 0 0 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        cursor: pointer;

        &, span {
          transition: 0.4s variables.$dnldBezier;
        }

        span {
          width: 30px;
          height: 4px;
          background: variables.$colorWhite;
          display: block;

          &:first-child {
            position: relative;
            transform-origin: 0 0;

            &::before {
              content: "";
              position: absolute;
              max-width: 0;
              width: 100%;
              height: 100%;
              left: 50%;
              transform: translateX(-50%);
              background: variables.$colorBlack;
              display: block;
              transition: 0.4s variables.$dnldBezier;
            }
          }

          &:last-child {
            transform-origin: 0 100%;
          }
        }
      }
    }

    &.squish, body:has(main.page:not(.home)) & {
      .wrap {
        @include applyHeaderAndLogoHeight($smallHeaderHeight);
      }

      .menu-button {
        width: 22px;
      }
    }

  }

  // Menu animation for mobile

  #menu-active-overlay {
    background: rgba(variables.$colorBlack, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100lvh;
    pointer-events: none;
    z-index: 99;
    opacity: 0;
    transition: 0.4s variables.$dnldBezier;
  }

  body:has(.menu-button.open) {
    overflow: hidden;

    #menu-active-overlay {
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    }

    .menu-button {

      span {
        &:first-child {
          transform: rotate(40deg) translateY(1px);

          &::before {
            max-width: 50%;
          }
        }

        &:last-child {
          transform: rotate(-40deg) translateY(-1px);
        }

        &:nth-child(2) {
          opacity: 0;
        }
      }
    }

    .header__main {
      transform:translateX(0);
    }
  }
}