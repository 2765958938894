@use "variables";

@mixin restrainFirstChildLastChildMargins {
  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

@layer typography {

  h1 {
    font-size: 50px;

    line-height: 1.25;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    //letter-spacing: 0.1em;
    position: relative;
    margin-bottom: 1em;

    border-left: 0.25em solid variables.$colorBlack;
    padding-left: 0.5em;
  }

  h2 {
    font-size: 40px;

    line-height: 1.25;
    text-align: left;
    justify-content: flex-start;
    align-items: center;
    //letter-spacing: 0.1em;
    position: relative;
    margin-bottom: 1em;

    border-left: 0.25em solid variables.$colorBlack;
    padding-left: 0.5em;

    // &::after {
    //   content: "";
    //   position: absolute;
    //   bottom: -0.3em;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   width: 150px;
    //   height: 0.1em;
    //   background-color: $colorBlack;
    // }

    // Slightly smaller as there is already a main header
    .left-right-sections section & {
      font-size: 28px;
      margin-left: 32px;
      padding-left: 0;
      border-left: 0;
    }

    // @media #{$smartphone-breakpoint} {
    //   font-size: 28px;
    // }
  }

  h3 {
    font-size: 23px;
    font-weight: bold;
    line-height: 1.25;
    //letter-spacing: 0.05em;

    border-left: 0.25em solid variables.$colorBlack;
    padding-left: 0.5em;
    margin: 1.5em 0 0.5em;

    @include restrainFirstChildLastChildMargins();

    @media #{variables.$smartphone-breakpoint} {
      font-size: 20px;
    }
  }

  p {
    font-size: 18px;
    line-height: 1.5;
    margin: 1em 0;
    // letter-spacing: 1px;

    @include restrainFirstChildLastChildMargins;

    @media #{variables.$smartphone-breakpoint} {
      font-size: 16px;
    }
  }

  .caption {
    .caption-text {
      p {
        font-size: 14px;
        line-height: 1.4;
      }

    }
  }

  strong, a {
    font-size: 1.05em;
    font-weight: bold;
  }

  a {
    text-decoration: underline;
  }
}