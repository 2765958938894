@layer reset {
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  a, abbr, address, applet, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, hr, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, s, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, tt, u, ul, var, video {
    margin: 0;
    padding:0
  }

  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display:block
  }

  h1, h2, h3, h4, h5, h6 {
    font-size:100%
  }

  table {
    border-collapse: collapse;
    border-spacing:0
  }

  b, mark, strong {
    font-weight: 700;
    font-style:inherit
  }

  address, cite, dfn, em, i, q, var {
    font-style: italic;
    font-weight:inherit
  }

  abbr[title], dfn[title] {
    cursor: help;
    border-bottom:1px dotted
  }

  ins {
    border-bottom:1px solid
  }

  a, ins, u {
    text-decoration:none
  }

  del, s {
    text-decoration:line-through
  }

  code, kbd, pre, samp {
    font-family:monospace
  }

  small {
    font-size:.75em
  }

  img {
    border: 0;
    font-style:italic
  }

  input, optgroup, option, select, textarea {
    font:inherit
  }

  * {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding:0
  }

  article, aside, details, figcaption, figure, footer, header, hgroup, nav, section {
    display:block
  }

  audio[controls], canvas, video {
    display:inline-block
  }

  html {
    font-size: 100%;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust:100%
  }

  body {
    margin: 0;
    text-rendering:optimizeLegibility
  }

  img {
    border: 0;
    -ms-interpolation-mode: bicubic;
    display:block
  }

  embed, img, object {
    max-width: 100%;
    height:auto
  }

  input {
    -webkit-appearance:none
  }


}

