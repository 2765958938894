@use "sass:color";
$smartphone-breakpoint: 'screen and (max-width: 768px)';
$tablet-breakpoint: 'screen and (max-width: 1024px)';
$tabletOnly: '#{$tablet-breakpoint} and (min-width: 768px)';

// Fixed Colors
$colorWhite: #f5efed;
$colorBlack: #000100;
$colorDarkGrey: #000000cd;
$colorBackgroundGrey: #6e6e6e;
$colorBackgroundDarkGrey: #3e3e3e;
$colorCoolGrey: #8c93a8;
$colorEnglishViolet: #62466b;
$colorChineseViolet: #856084;
$colorEnglishVioletDark: #45364b;
$colorRaisinBlack: #2d2327;
$colorVibrantBlue: #3498db;
$colorCafeAuLait: #a7754d;
$colorCeladonBlue: #4281a4;

// Social media colours
$colorSpotify: #1DB954;
$colorBandcamp: #629aa9;

$backgroundColor: $colorWhite;
$bodyColor: $colorBlack;
$bodyColorSecondary: color.adjust($bodyColor, $lightness: 20%);
$bodyColorTertiary: color.adjust($bodyColorSecondary, $lightness: 20%);
$bodyColorQuaternary: color.adjust($bodyColorTertiary, $lightness: 20%);

$fillColor: $colorChineseViolet;
$fillColorSecondary: color.adjust($fillColor, $lightness: 20%);
$fillColorTertiary: color.adjust($fillColor, $lightness: 40%);
$fillColorQuaternary: color.adjust($fillColor, $lightness: 60%);

$titleColor: $colorDarkGrey;
$titleColorHover: color.adjust($titleColor, $lightness: 20%);

$linkColor: $fillColor;
$linkColorHover: color.adjust($linkColor, $lightness: 40%);

$dnldBezier: cubic-bezier(0.85, 0, 0.15, 1);

$zoomScaleFactor: 1.1;
$zoomSmallScaleFactor: 1.02;

$bodyFont:  "Instrument Sans", "Almarai", sans-serif;
$titleFont: "Instrument Sans", "Almarai", sans-serif;;

@function imageFromAssetFolder($image) {
  @return url('/assets/img/' + $image);
}

@mixin trianglePseudoElem($width, $white: true) {
  width: $width;
  height: calc($width * 3 / 2);
  background: {
    size: contain;
    repeat: no-repeat;
    color: transparent !important;
  };
  @if $white {
    background-image: imageFromAssetFolder('triangle-white.svg');
  } @else {
    background-image: imageFromAssetFolder('triangle.svg');
  }
}

@mixin bgElem($bgType: 'black') {
  $bgColor: $colorBlack;
  $textColor: $colorWhite;

  @if $bgType == 'white' {
    $bgColor: $colorWhite;
    $textColor: $colorBlack;
  }

  background-color: $bgColor;

  * {
    color: $textColor;

    border-color: $textColor;

    &:not(.fab):not(.fa):not(.fa-regular) {
      &::before, &::after {
        color: $textColor;
        background-color: $textColor;
      }
    }

  }
  h2 {
    border-left-color: $textColor !important;
  }
}

@mixin corneredElem($cornerWidth) {
  clip-path: polygon($cornerWidth 0, calc(100% - $cornerWidth) 0, 100% $cornerWidth, 100% calc(100% - $cornerWidth), calc(100% - $cornerWidth) 100%, $cornerWidth 100%, 0% calc(100% - $cornerWidth), 0 $cornerWidth);
}