@use "sass:color";
@use "variables";

@layer content {
    html {
      scroll-behavior: smooth;
    }
    body {
        font-family: variables.$bodyFont;
    }

    section {
        padding: 100px 0;

        &.black {
          @include variables.bgElem;
        }

        @media #{variables.$smartphone-breakpoint} {
          padding: 80px 0;
        }

        .left-right-sections {
          display: grid;
          grid-gap: 60px;
          grid-template-columns: 1fr 1fr;
          //margin-top: 90px;

          section {
            padding: 30px 0;
            @include variables.bgElem;
          }

          @media #{variables.$smartphone-breakpoint} {
            grid-template-columns: 1fr;
            margin-top: 30px;
          }
        }
    }

    .container {
        max-width: 1132px;
        margin: auto;
        padding: 0 16px;
        width: 100%;
        box-sizing: border-box;
    }

    .hero {
      overflow: clip;
        height: 100lvh;
        background-image: linear-gradient(to bottom left, #000100, #000100 55%, white);
        background-size: 200% 200%;
        position: relative;
        padding: 0;
        animation: backgroundMove 4s ease-in-out infinite;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        @keyframes backgroundMove {
          0%{background-position:10% 0%}
          50%{background-position:91% 100%}
          100%{background-position:10% 0%}
        }

        &__figure {
          animation: fadeInHero 0.7s ease-in-out, expandHero 2.5s ease-out;
          position: relative;
          height: 80vh;
          object-fit: cover;
          object-position: center bottom;
          max-width: none;
          max-width: 110vw;
          transform-origin: center bottom;

          @keyframes fadeInHero {
            from { opacity: 0 }
            to { opacity: 1 }
          }
          @keyframes expandHero {
            from {
              -webkit-transform: scale(0.95);
              transform: scale(0.95);
            }
            to {
              -webkit-transform: scale(1);
              transform: scale(1);
            }
          }
        }

        + #header-observer {
          position: absolute;
          top: 0;
          left: 0;
          width: 100vw;
          z-index: 1;
        }

        &__scroll-indicator {
          animation: makeVisible 1s linear;
          position: absolute;
          z-index: 1;
          right: max(3vw, 16px);
          bottom: max(3vw, 16px);
          display: flex;
          flex-direction: column;
          align-items: center;

          @keyframes makeVisible {
            0% {
              opacity: 0;
            }
            50% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }

          @media #{variables.$smartphone-breakpoint} {
            bottom: 0;
            top: calc(100svh - 16px);
            height: 40px;
            transform: translateY(-100%);
          }

          .triangle {
            @include variables.trianglePseudoElem(16px);
            margin-bottom: 8px;
            transform: rotate(90deg);
            animation: scrollIndicator 2s linear 2s infinite;
            animation-delay: 0.5s;

            @keyframes scrollIndicator {
              0% {
                transform: rotate(90deg) translateX(-16px);
              }
              50% {
                transform: rotate(90deg) translateX(-8px) /* scaleY(-1) */;
              }
              100% {
                transform: rotate(90deg) translateX(0) /* scaleY(1) */
              }
            }
          }

          span {
            margin-top: -4px;
            font-size: 10px;
            color: white;
          }
        }
    }

    .about {
      h2 {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px 4px;
        svg {
          height: 1.5ch;
        }
      }

      .text-box-container {
        display: flex;
        flex-direction: row;

        .image-wrap, .text-box {
            flex: 50%;
        }

        @media #{variables.$smartphone-breakpoint} {
            flex-direction: column;

            .image-wrap, .text-box {
                flex: 100%;
            }

            .image-wrap {
                min-height: 50dvh;
            }
        }
      }

      .image-wrap {
        position: relative;
      }

      .image {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
      }

      .text-box {
        background-color: variables.$colorBackgroundDarkGrey;

        h3 {
            border-color: variables.$colorBlack;
        }
      }

      .carousel-inner {
        height: 100%;

        @media #{variables.$smartphone-breakpoint} {
          min-height: 50dvh;
        }
      }

      .slide-content {
        height: 100%;
      }

      .slide img {
        height: 100%;
      }

      .carousel-pagination {
        display: none;
      }

      .carousel-btn--prev-next  {
        width: 30px;
        aspect-ratio: 1 / 1;
        border-radius: 50%;
        background-color: rgba(0,0,0,0.6);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .carousel-btn--prev-next img {
        width: 40%;
        height: auto;
        transform: translateX(15%);
      }

      .carousel-btn--prev img {
        transform: scaleX(-1) translateX(20%);
      }

    }

    // Homepage Events Section
    .events {
      &__container {
        display: grid;
        gap: 30px;
        justify-content: center;

        @media not #{variables.$tablet-breakpoint} {
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        }

        > * {
          min-width: 0px;
        }

        .post {
            position: relative;
            width: 100%;
            max-width: 450px;

            &:first-child {
              margin-top:0;
            }

            &:hover .image, &:hover .video {
              transform:scale(variables.$zoomScaleFactor);

            }

            &:hover .content {
              box-shadow:0 6px 16px rgba(0, 0, 0, .1);
            }

            &:hover .title a {
              text-decoration: underline;
            }

            .image, .video {
              transition:all 1s cubic-bezier(.2, .3, 0, 1);
            }

            .video {
              max-width: 100%;
            }

            @media not #{variables.$tablet-breakpoint} {
              .image, .image img, .video, .video video {
                display: block;
                aspect-ratio: 3 / 2;
                width: 100%;
                object-fit: contain;
                object-position: center bottom;
                // background-color: rgba(20,21,20);
              }
            }

            .content-wrap {
              width: 90%;
              //margin: -36px 10% 0;
              margin: -30px auto 0;
              position: relative;
              z-index: 1;
              padding: 0;
              background: #fff;
              @include variables.bgElem('white');
              // @include corneredElem(16px);

              @media #{variables.$smartphone-breakpoint} {
                width: 95%;
              }
            }

            .content {
                padding: 10px;
                box-shadow: 0 6px 16px rgba(0, 0, 0, .05);
                transition:all .4s cubic-bezier(.2, .3, 0, 1);
                color: variables.$colorBlack;
            }

            .title {
                background-color: variables.$colorBlack;
                color: variables.$colorWhite;
                border: none;
                padding: 0.25em 0.75em;

                @media #{variables.$smartphone-breakpoint} {
                  font-size: 26px;
                }

                a {
                    font-weight:700;
                    color: variables.$colorWhite;
                    text-decoration: none;
                }
            }

            .event-date {
              color: #bbb;
              font-size: 0.8em;
              display: block;
            }

            .location {
              margin-top: 5px;
              font-size: 15px;
              color: variables.$colorDarkGrey;

              i {
                color: variables.$colorCoolGrey;
              }
            }

            a.button {
              margin-top: 16px;
              font-size: 0.8em;
            }
        }
      }

      .past-events-link {
        display: block;
        margin-top: 2em;
      }
    }

    // Releases
    section.releases {
        .release {
            position: relative;
            width: 100%;
            border-bottom: 0.5em solid variables.$colorWhite;

            &:first-child {
              margin-top:0;
            }

            &hover {
                .image {
                    transform:scale(variables.$zoomScaleFactor);
                }

                .content {
                    box-shadow:0 6px 16px rgba(0, 0, 0, .1);
                }

                .title a {
                    color: variables.$titleColorHover;
                }
            }

            .image-wrap {
              img {
                display: block;
                width: 100%;
                max-height: 350px;
                object-fit: contain;
              }
            }

            .image {
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              background-position: center;
              background-repeat: no-repeat;
              background-size: cover;
              transition:all 1s cubic-bezier(.2, .3, 0, 1);
            }

            .content-wrap {
                padding: 10px;
            }

            .content {
              position: relative;
              margin-top: -10px;
              padding: 10px;

              box-shadow: 0 6px 16px rgba(0, 0, 0, .05);
              transition:all .4s cubic-bezier(.2, .3, 0, 1);
              color: variables.$colorBlack;

              .description {
                color: color.adjust(variables.$colorWhite, $lightness: -10%);
              }
            }

            .title {
                background: variables.$colorWhite;
                color: variables.$colorBlack;
                margin-left: -20px;

                a {
                    font-weight:700;
                }
            }

            .links {
                list-style: none;
                padding: 0;

                li {
                  display: inline-block;
                  margin-right: 5px;
                }

                i {
                    font-size: 2em;

                    &.fa-spotify {
                        color: variables.$colorSpotify;
                    }

                    &.fa-bandcamp {
                      color: variables.$colorBandcamp;
                    }

                    &:hover {
                        transform:scale(variables.$zoomScaleFactor);
                        transition: ease-in-out;
                    }
                }
            }

        }
    }

    section.media {
        a {
            color: variables.$linkColor;
        }

        background-color: #f1f1f1;

        ul.media-gallery {
            list-style: none;

            .media {
                margin: 5px;
                display: inline-block;
                width: 180px;


                img {
                  width: 100%;
                  height: auto;
                }
              }
        }
    }

    // Event show Page
    .event-page {
        h2, h3 {
            border-left: none;
        }

        .event-hero {
            overflow: hidden;
            text-align: center;
            position: relative;
            margin-bottom: -20%;

            img, .image, video {
                display: block;
                width: 100%;
                object-fit: cover;
                object-position: center;
                height: auto;
                max-height: 80vh;
            }

            &::after {
                content: '';
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                height: min(50%, 80px);
                background: linear-gradient(to bottom, rgba(white, 0), rgba(white, 1));
            }
        }

        .content-wrap {
            position: relative;
            padding: 20px 10px;
            background-color: #e3e3e3;

            p {
                margin-top: 20px;
            }
        }

        .event-content {
            padding: 20px;
            background-color: white;
        }

        .title {
            background-color: variables.$colorBlack;
            color: variables.$colorWhite;
            font-size: 30px;
            border: none;
            padding: 0.25em 0.75em;

            margin-bottom: 0px;

            @media #{variables.$smartphone-breakpoint} {
              border-left: 0;
              padding: 0 16px;
            }
        }

        .event-date {
          color: #bbb;
          font-size: 0.8em;
          display: block;
        }

        .location {
          padding-left: 5px;
          border-left: 5px solid variables.$colorBlack;
          font-size: 15px;
          color: variables.$colorDarkGrey;

          i {
            color: variables.$colorCoolGrey;
          }
        }
    }

    // Events List Page
    .page.events {
      h1 {
        border-left: none;
        padding-left: 0;
        margin-bottom: 0;
      }

      .past-anchor {
        position: relative;
        top: -60px;
      }

      h2 {
        margin-bottom: 0em;
      }
      .container {
        display: grid;

        row-gap: 45px;

        > * {
           min-width: 0;
        }

        article.post {
          &:nth-child(2) {
            margin-top: -45px; // First after title
          }
        }
      }

      article.post {
        display: grid;
        grid-template-columns: clamp(10px, 20vw, 200px) 1fr;
        gap: 0;
        gap: 16px;

        transition:all 1s cubic-bezier(.2, .3, 0, 1);

        @media #{variables.$smartphone-breakpoint} {
          grid-template-columns: auto;
          gap: 0;
        }

        > * {
          min-width: 0;
       }

       &:hover {
          transform:scale(variables.$zoomSmallScaleFactor);
       }

        .image-wrap {
          display: block;

          img, video {
            width: 100%;
            object-fit: cover;
          }

          @media #{variables.$smartphone-breakpoint} {
            img, video {
              min-height: 100%;
              max-height: 40svh;
              object-fit: contain;
              background-color: variables.$colorBlack;
            }

          }
        }

        .content-wrap {
          padding: 16px;
          background-color: #e3e3e3;

          p {
              margin-top: 20px;
          }
        }

        .event-content {
            padding: 20px;
            background-color: white;
        }

        .title {
            background-color: variables.$colorBlack;
            color: variables.$colorWhite;
            border: none;
            padding: 0.25em 0.75em;
            font-size: 26px;
            margin-bottom: 0px;

            @media #{variables.$smartphone-breakpoint} {
              font-size: 18px;
            }

            a {
              color: variables.$colorWhite;
            }
        }

        .event-date {
          color: #bbb;
          font-size: 0.8em;
          display: block;
        }

        .location {
          padding-left: 5px;
          border-left: 5px solid variables.$colorBlack;
          font-size: 15px;
          color: variables.$colorDarkGrey;

          i {
            color: variables.$colorCoolGrey;
          }
        }

        a.button {
          margin-top: 16px;
        }

      }
    }

    // Media Kit Page
    .page.media {
      h1 {
        border-left: none;
        padding-left: 0;
        margin-bottom: 0;
      }

      h2 {
        margin-bottom: 1em;
      }

      section {
        padding: 60px 0;
      }

      ul {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        gap: 30px;

        li {
          display: flex;
          flex-direction: column;
          align-items: stretch;

          p {
            text-align: center;
            margin-top: 0.5em;
          }
        }

        @media #{variables.$smartphone-breakpoint} {
          grid-template-columns: 1fr 1fr;
        }
      }

      section:not(.black) {
        a {
          color: variables.$colorBlack;
        }
      }

      ul:not(.other-assets) {
        li {
          img {
            transition: 0.3s ease-in-out;
            transform-origin: bottom center;
          }
          &:has(a:hover) {
            img {
              transform: scale(1.05);
            }

            a {
              text-decoration: underline;
            }
          }
        }
      }

      ul.other-assets {
        grid-template-columns: 1fr;
        li {
          display: flex;
          align-items: center;
          flex-direction: row;
          gap: 8px;
          &::before {
            content: "";
            @include variables.trianglePseudoElem(1ch, true);
          }
        }
      }

    }
}