@use "variables";

.pc-only {
  @media #{variables.$tablet-breakpoint} {
    display: none;
  }
}

.tablet-and-below {
  @media not #{variables.$tablet-breakpoint} {
    display: none;
  }
}

.tablet-and-above {
  @media #{variables.$smartphone-breakpoint} {
    display: none;
  }
}

.sp-only {
  @media not #{variables.$smartphone-breakpoint} {
    display: none;
  }
}

