@use "variables";

// @mixin repeating-flex {
//   --_flex-repeat: var(--flex-repeat, var(--layout-default-repeat));
//   --_repeating-flex-gap: var(--flex-gap, var(--layout-default-gap));

//   --_gap-count: calc(var(--_flex-repeat) - 1);
//   --_gap-repeater-calc: calc(
//     var(--_repeating-flex-gap) / var(--_flex-repeat) * var(--_gap-count)
//   );

//   display: flex;
//   flex-wrap: wrap;
//   gap: var(--_repeating-flex-gap);

//   > * {
//     flex: 1 1 calc((100% / var(--_flex-repeat)) - var(--_gap-repeater-calc));
//   }
// }

@layer layout {
   main.page:not(.home) {
      padding-top: 100px;
   }
   main.page.event-page {
    padding-top: 70px;
   }

   // Adding a gap at the end for pages without a clear "ending section".
   main.page.event-page,
   main.page.events {
    padding-bottom: 60px;

    @media #{variables.$smartphone-breakpoint} {
         padding-bottom: 30px;
      }
   }
   
}